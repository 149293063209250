export interface TableConfig {
  pageIndex: number;
  pageSize: number;
  sortOn: string;
  sortOrder: string;
  totalRecords: number;
}

export interface RDSelectOptions {
  label: string;
  value: string;
  disabled?: boolean;
}
