export enum ApiEndpoints {
  LAUNCH_BAR = 'launch-bar',
  LOGIN = 'login',
  FAQ = 'faq',
  REGION_TYPE = 'region_type',
  DATA_SOURCE = 'data_source',
  CONCEPT_SEARCH = 'concept_set_search',
  DATA_SOURCE_DELETE = 'admin/delete_data_source',
  ADMIN_DATASET_LIST = 'admin/onboarding_details',
  ADMIN_DATASET_CREATE = 'admin/datasource/onboarding',
  ADMIN_ERROR_LOG = 'admin/error-log',
  DATASET_MORE_INFO = 'more_info', // params: data_src_id & con_set_id
  CONCEPTS_LIST = 'concept_set_list', // URL: /data_source/<<data source id>>/therapeutic_areas
  DATASET_DOWNLOAD = 'generate_graphs_pdf',
  DOWNLOAD_STATUS = 'generate_pdf_status',
  PROJECT_DETAILS = 'project_details',
}
