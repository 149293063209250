import { TitleCasePipe } from '@angular/common';

const titleCaseTransform = new TitleCasePipe().transform;

export const ADMIN_SERVICES_TABLE_COLUMNS = () => [
  {
    name: 'Dataset Name',
    key: 'dataSourceName',
    dataType: 'string',
    prefix: '',
    suffix: '',
    sortable: true,
    align: 'left',
    showIcon: false,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    ellipsis: true,
  },
  {
    name: 'Triggered By',
    key: 'triggeredBy',
    dataType: 'string',
    prefix: '',
    suffix: '',
    sortable: true,
    align: 'left',
    showIcon: false,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
  },
  {
    name: 'Start Date',
    key: 'startTimestamp',
    dataType: 'date',
    dateFormat: 'd MMM y',
    prefix: '',
    suffix: '',
    sortable: true,
    align: 'left',
    showIcon: false,
    defaultSortOrder: 'desc',
    sortDirections: ['ascend', 'descend'],
  },
  {
    name: 'End Date',
    key: 'endTimestamp',
    dataType: 'date',
    dateFormat: 'd MMM y',
    prefix: '',
    suffix: '',
    sortable: true,
    align: 'left',
    showIcon: false,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
  },
  {
    name: 'Status',
    key: 'onboardingStatus',
    dataType: 'string',
    prefix: '',
    suffix: '',
    sortable: true,
    align: 'left',
    showIcon: false,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    formatter: (data: string) => titleCaseTransform(data),
  },
];

export const StatusIconBefore = new Map([
  ['COMPLETED', 'custom:check-circle'],
  ['FAILED', 'custom:error-diamond'],
  ['QUEUED', 'custom:clock-pending'],
  ['IN PROGRESS', 'custom:clock-pending'],
]);

export const StatusIconAfter = new Map([['FAILED', 'custom:file-preview']]);

export interface AdminServicesDataset {
  onboardingId: number;
  dataSourceName: string;
  processName: string;
  onboardingStatus: string;
  startTimestamp: string;
  endTimestamp: string;
  updatedTimestamp: string;
  triggeredBy: string;
  clusterId: string;
  stepId: string;
  jobId: string;
  startTimestamp_iconAfter?: any[];
  endTimestamp_iconAfter?: any[];
  onboardingStatus_iconBefore?: any[];
  onboardingStatus_iconAfter?: any[];
}

export interface Column {
  align: string;
  customFilterVisible: boolean;
  dataType: string;
  defaultSortOrder: string;
  key: string;
  name: string;
  prefix: string;
  showIcon: boolean;
  sortDirections: ('ascend' | 'descend')[];
  sortable: boolean;
  suffix: string;
}

export interface AdminServicesDatasetListApiResponse {
  status: string;
  message: string;
  datasourceCount: number;
  datasources: AdminServicesDataset[];
}

export type InputField =
  | 's3BucketName'
  | 's3Path'
  | 'name'
  | 'type'
  | 'regionName'
  | 'geographicalDepth'
  | 'displayName'
  | 'description';

export interface CreateDatasetApiResponse {
  status: string;
  message: string;
  title_present: boolean;
  datasetCreated: boolean;
  messageDisplay: string;
}

export interface CreateDatasetApiRequest {
  dataSourceName: string;
  dataSourceDisplayName: string;
  dataSourceRegion: string;
  dataSourceType: string;
  geoDepth: string;
  dataSourceDesc: string;
  s3BucketName: string;
  s3BucketPath: string;
  chartsList: any[];
}
