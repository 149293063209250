import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@core/interceptors';
import {
  RDAlertModule,
  RDButtonModule,
  RDHeaderModule,
  RDModalModule,
  RDNotificationModule,
  RDSelectModule,
  RDSiderModule,
  RDSpinnerModule,
} from '@zs-ca/angular-cd-library';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './layout/header/header.component';
import { SiderComponent } from './layout/sider/sider.component';
import { NotFoundComponent } from './not-found/not-found.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SiderComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OverlayModule,
    NzIconModule,
    RDAlertModule,
    RDButtonModule,
    RDHeaderModule,
    RDModalModule,
    RDNotificationModule,
    RDSiderModule,
    RDSpinnerModule,
    RDSelectModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
