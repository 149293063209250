import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiEndpoints } from '@core/constants';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private cookieService: CookieService, private http: HttpClient) {}

  selectedProject = '';

  ngOnInit(): void {
    this.getProjectName();
  }
  selectedRegion = localStorage.getItem('region');

  environment = environment.config.client;

  private getProjectName() {
    const url = ApiEndpoints.PROJECT_DETAILS;
    this.http.get(url).subscribe((data: any) => {
      this.selectedProject = data['data'].project_name;
    });
  }
}
