import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpoints } from '@core/constants';
import { AppPages, SIDEBAR_MENU_ITEMS, UserDetails } from '@core/models';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  private userDetails = new BehaviorSubject<UserDetails>({});

  setUserDetails(userDetails: UserDetails): void {
    this.userDetails.next({ ...userDetails });
  }

  getUserDetails(): Observable<UserDetails> {
    return this.userDetails.asObservable();
  }

  hasAdminPermissions(): boolean {
    return this.userDetails?.value?.permissions?.includes('admin') || false;
  }

  checkAdminPermissionsFromServer(): Observable<boolean> {
    return this.http.get<UserDetails>(ApiEndpoints.LOGIN).pipe(
      map((response) => {
        if (response) {
          this.setUserDetails(response);
          return response?.permissions?.includes('admin') || false;
        }
        return false;
      })
    );
  }

  isUserDetailsAvailable(): boolean {
    return !!Object.keys(this.userDetails?.value)?.length;
  }

  getAvailableScreens(): RdSiderMenuItem[] {
    return this.hasAdminPermissions()
      ? [
          SIDEBAR_MENU_ITEMS[AppPages.DATASETS],
          SIDEBAR_MENU_ITEMS[AppPages.ADMIN],
        ]
      : [SIDEBAR_MENU_ITEMS[AppPages.DATASETS]];
  }

  selectedRegion(): any {
    return this.userDetails?.value?.region
      ? this.userDetails?.value?.region
      : 'US';
  }
}
