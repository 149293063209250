export const API_SUCCESS = 'success';

export const SOMETHING_WENT_WRONG =
  'Something went wrong. Please try again later.';

export const DOWNLOAD_DATASET_DETAILS_FAILED =
  'Unable to download dataset details. Please try again later.';

export const DOWNLOAD_COMPARE_DATASETS_FAILED =
  'Unable to download dataset comparison details. Please try again later.';

export const DATASET_SEARCH_CHARACTERS_LIMIT =
  'Enter search keyword with minimum 3 characters.';

export const DATASET_SEARCH_NO_DATA = 'No concepts found.';

export const DUPLICATE_DATASET_NAME = 'Dataset Display Name already exists.';

export const NO_SPACE_DATASET_NAME =
  'No whitespaces are allowed in the Data Source Name.';

export const MIN_LENGTH_DATASET_NAME =
  'Data Source Name must have minimum 3 characters.';

export const MIN_LENGTH_DATASET_DISPLAY_NAME =
  'Dataset Display Name must have minimum 3 characters.';

export const MAX_LENGTH_DATASET_DESCRIPTION =
  'Dataset Description cannot be more than 100 characters.';
