export interface Dataset {
  dataSourceRegion: string;
  dataSourceName: string;
  updatedTimestamp: string;
  Period: string;
  Exact_Date: string;
  PatientCount: string | number;
  dataSourceType: string;
  dataSourceId: string;

  datasetDetails?: DatasetDetails;

  dataSetCharts?: DatasetCharts;

  isChecked?: boolean; // Config var for rd-table
  isCheckboxDisabled?: boolean; // Config var for rd-table
  updatedTimestamp_iconAfter?: any[]; // Config var for rd-table
  Period_iconAfter?: any[]; // Config var for rd-table
}

export interface DatasetComparison {
  [id: string]: Dataset;
}

export interface DatasetDetails {
  exact_date: string;
  patientCount: string;
  claimCoverage: string;
  populationCovered: string;
  geoLocation: string;
  geoDepth: string;
  dataSourceName: string;
}

export interface DatasetMoreInfoApiResponse {
  status: string;
  message: string;
  info: DatasetDetails;
  dataSourceId: number;
  charts: DatasetCharts;
}

export interface DatasetCharts {
  claimDistributionByTime: any; // TODO: Add type.
  claimPatientDistributionByTime: any; // TODO: Add type.
  distributionByIndication: any; // TODO: Add type.
  populationDistributionByState: any; // TODO: Add type.
  physicianDistributionPrevelanceCount: any; // TODO: Add type.
  ageDistributionByGender: any; // TODO: Add type.
  populationDistributionByEthnicity: any; // TODO: Add type.
  overlapData: any; // TODO: Add type.
  claimDistributionPayer: any; // TODO: Add type.
  topTenDrugs: any; // TODO: Add type.
  topTenSpecialtyPhysicianCount: any; // TODO: Add type.
}

export interface ConceptListApiResponse {
  status: string;
  message: string;
  data: { conceptSetList: Concept[] };
  dataSourceName: string;
  dataSourceId: string;
  dataSourceType: string;
  updatedTimestamp: string;
}

export interface Concept {
  conceptName: string;
  conceptSetId: string;
  patientCount: string;
  percentage: string;
}

export type DataFetchStatus =
  | ''
  | 'loading'
  | 'available'
  | 'unavailable'
  | 'error';

export type DatasetProperties =
  | 'dataSourceRegion'
  | 'dataSourceName'
  | 'updatedTimestamp'
  | 'Period'
  | 'PatientCount'
  | 'dataSourceType'
  | 'dataSourceId';
