import { IconDefinition } from '@ant-design/icons-angular';

export const CUSTOM_ICONS: Array<IconDefinition> = [
  {
    name: 'database',
    icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V7.5H20.25V2.25C20.25 1.83516 19.9148 1.5 19.5 1.5ZM6.75 5.4375C6.23203 5.4375 5.8125 5.01797 5.8125 4.5C5.8125 3.98203 6.23203 3.5625 6.75 3.5625C7.26797 3.5625 7.6875 3.98203 7.6875 4.5C7.6875 5.01797 7.26797 5.4375 6.75 5.4375ZM3.75 21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V16.5H3.75V21.75ZM6.75 18.5625C7.26797 18.5625 7.6875 18.982 7.6875 19.5C7.6875 20.018 7.26797 20.4375 6.75 20.4375C6.23203 20.4375 5.8125 20.018 5.8125 19.5C5.8125 18.982 6.23203 18.5625 6.75 18.5625ZM3.75 15H20.25V9H3.75V15ZM6.75 11.0625C7.26797 11.0625 7.6875 11.482 7.6875 12C7.6875 12.518 7.26797 12.9375 6.75 12.9375C6.23203 12.9375 5.8125 12.518 5.8125 12C5.8125 11.482 6.23203 11.0625 6.75 11.0625Z" fill="white"/>
      </svg>
    `,
  },
  {
    name: 'team-settings',
    icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5355 12.4645C14.5979 11.5268 13.3261 11 12 11C10.6739 11 9.40215 11.5268 8.46447 12.4645C7.52678 13.4021 7 14.6739 7 16V22H13.5278C12.5777 20.9385 12 19.5367 12 18C12 15.5478 13.4711 13.4391 15.579 12.5085C15.5646 12.4937 15.5501 12.4791 15.5355 12.4645Z" fill="white"/>
      <path d="M5.008 15.656C5.03485 15.0968 5.12886 14.5428 5.288 14.006L5.119 14.02C4.2617 14.1139 3.46924 14.521 2.89363 15.1632C2.31803 15.8054 1.9998 16.6376 2 17.5V22H5V16L5.008 15.656Z" fill="white"/>
      <path d="M7.26777 8.73223C6.79893 8.26339 6.16304 8 5.5 8C4.83696 8 4.20107 8.26339 3.73223 8.73223C3.26339 9.20107 3 9.83696 3 10.5C3 11.163 3.26339 11.7989 3.73223 12.2678C4.20107 12.7366 4.83696 13 5.5 13C6.16304 13 6.79893 12.7366 7.26777 12.2678C7.73661 11.7989 8 11.163 8 10.5C8 9.83696 7.73661 9.20107 7.26777 8.73223Z" fill="white"/>
      <path d="M14.8284 3.17157C14.0783 2.42143 13.0609 2 12 2C10.9391 2 9.92172 2.42143 9.17157 3.17157C8.42143 3.92172 8 4.93913 8 6C8 7.06087 8.42143 8.07828 9.17157 8.82843C9.92172 9.57857 10.9391 10 12 10C13.0609 10 14.0783 9.57857 14.8284 8.82843C15.5786 8.07828 16 7.06087 16 6C16 4.93913 15.5786 3.92172 14.8284 3.17157Z" fill="white"/>
      <path d="M14.595 18.812C14.4682 18.2784 14.4682 17.7226 14.595 17.189L13.603 16.616L14.603 14.884L15.595 15.457C15.9932 15.0799 16.4745 14.8017 17 14.645V13.5H19V14.645C19.532 14.803 20.012 15.085 20.405 15.457L21.397 14.884L22.397 16.616L21.405 17.189C21.5316 17.7223 21.5316 18.2777 21.405 18.811L22.397 19.384L21.397 21.116L20.405 20.543C20.0068 20.9201 19.5255 21.1983 19 21.355V22.5H17V21.355C16.4745 21.1983 15.9932 20.9201 15.595 20.543L14.603 21.116L13.603 19.384L14.595 18.812ZM18 19.5C18.3978 19.5 18.7794 19.342 19.0607 19.0607C19.342 18.7794 19.5 18.3978 19.5 18C19.5 17.6022 19.342 17.2206 19.0607 16.9393C18.7794 16.658 18.3978 16.5 18 16.5C17.6022 16.5 17.2206 16.658 16.9393 16.9393C16.658 17.2206 16.5 17.6022 16.5 18C16.5 18.3978 16.658 18.7794 16.9393 19.0607C17.2206 19.342 17.6022 19.5 18 19.5Z" fill="white"/>
      </svg>
    `,
  },
  {
    name: 'patients',
    icon: `
      <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.666687 14.6666C0.666687 13.2521 1.22859 11.8956 2.22878 10.8954C3.22898 9.8952 4.58553 9.33329 6.00002 9.33329C7.41451 9.33329 8.77106 9.8952 9.77126 10.8954C10.7715 11.8956 11.3334 13.2521 11.3334 14.6666H10C10 13.6058 9.57859 12.5883 8.82845 11.8382C8.0783 11.0881 7.06089 10.6666 6.00002 10.6666C4.93915 10.6666 3.92174 11.0881 3.17159 11.8382C2.42145 12.5883 2.00002 13.6058 2.00002 14.6666H0.666687ZM6.00002 8.66663C3.79002 8.66663 2.00002 6.87663 2.00002 4.66663C2.00002 2.45663 3.79002 0.666626 6.00002 0.666626C8.21002 0.666626 10 2.45663 10 4.66663C10 6.87663 8.21002 8.66663 6.00002 8.66663ZM6.00002 7.33329C7.47335 7.33329 8.66669 6.13996 8.66669 4.66663C8.66669 3.19329 7.47335 1.99996 6.00002 1.99996C4.52669 1.99996 3.33335 3.19329 3.33335 4.66663C3.33335 6.13996 4.52669 7.33329 6.00002 7.33329Z" fill="#454250"/>
      </svg>
    `,
  },
  {
    name: 'balance',
    icon: `
      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.64547 0V0.824862L10.8726 1.90144L13.2168 1.12047L13.6254 2.34421L11.6684 2.99674L13.6667 8.49066C12.9619 9.22451 11.9699 9.68148 10.8726 9.68148C9.77475 9.68148 8.78337 9.22451 8.07856 8.49066L10.0755 2.99674L7.64547 2.18608V10.9723H10.2272V12.2632H3.77288V10.9723H6.35461V2.18608L3.92262 2.99674L5.92088 8.49066C5.21671 9.22451 4.22468 9.68148 3.12745 9.68148C2.02957 9.68148 1.03819 9.22451 0.333374 8.49066L2.33034 2.99674L0.374682 2.34485L0.78324 1.11982L3.12745 1.9008L6.35461 0.824862V0H7.64547ZM10.8726 4.5845L9.60888 8.0608C9.98775 8.27444 10.4202 8.39062 10.8726 8.39062C11.3244 8.39062 11.7569 8.27444 12.1364 8.06144L10.8726 4.58386V4.5845ZM3.12745 4.5845L1.86369 8.0608C2.24256 8.27444 2.675 8.39062 3.12745 8.39062C3.57925 8.39062 4.01169 8.27444 4.3912 8.06144L3.12745 4.58386V4.5845Z" />
      </svg>
    `,
  },
  {
    name: 'bin',
    icon: `
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66671 1.6662V0.33287H10.3334V1.6662H13.6667V2.99954H12.3334V12.9995C12.3334 13.1763 12.2631 13.3459 12.1381 13.4709C12.0131 13.596 11.8435 13.6662 11.6667 13.6662H2.33337C2.15656 13.6662 1.98699 13.596 1.86197 13.4709C1.73695 13.3459 1.66671 13.1763 1.66671 12.9995V2.99954H0.333374V1.6662H3.66671ZM3.00004 2.99954V12.3329H11V2.99954H3.00004ZM5.00004 4.99954H6.33337V10.3329H5.00004V4.99954ZM7.66671 4.99954H9.00004V10.3329H7.66671V4.99954Z" />
      </svg>
    `,
  },
  {
    name: 'check-circle',
    icon: `
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM10.0234 4.71406L6.73281 9.27656C6.68682 9.34076 6.62619 9.39306 6.55595 9.42914C6.48571 9.46523 6.40787 9.48405 6.32891 9.48405C6.24994 9.48405 6.17211 9.46523 6.10186 9.42914C6.03162 9.39306 5.97099 9.34076 5.925 9.27656L3.97656 6.57656C3.91719 6.49375 3.97656 6.37813 4.07812 6.37813H4.81094C4.97031 6.37813 5.12187 6.45469 5.21562 6.58594L6.32812 8.12969L8.78438 4.72344C8.87813 4.59375 9.02812 4.51562 9.18906 4.51562H9.92188C10.0234 4.51562 10.0828 4.63125 10.0234 4.71406Z" fill="#00AA67"/>
      </svg>
    `,
  },
  {
    name: 'clock-pending',
    icon: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 12.1423 12.1423 15.5 8 15.5ZM8.75 8V4.25H7.25V9.5H11.75V8H8.75Z" fill="#F3BA2F"/>
      </svg>
    `,
  },
  {
    name: 'error-diamond',
    icon: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.05 7.5498L8.375 0.874805C8.15 0.649805 7.775 0.649805 7.55 0.874805L0.875003 7.5498C0.650003 7.7748 0.650003 8.14981 0.875003 8.3748L7.55 15.0498C7.775 15.2748 8.15 15.2748 8.375 15.0498L15.05 8.3748C15.35 8.14981 15.35 7.7748 15.05 7.5498ZM8.75 11.7498H7.25V10.2498H8.75V11.7498ZM8.75 9.49981H7.25V4.2498H8.75V9.49981Z" fill="#B21111"/>
      </svg>
    `,
  },
  {
    name: 'file-preview',
    icon: `
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0H1C0.4 0 0 0.5 0 1V19C0 19.5 0.4 20 1 20H17C17.6 20 18 19.5 18 19V5L13 0ZM16 18H2V2H12V6H16V18Z" fill="#27A6A4"/>
      <path d="M5.7 7.2C4.4 8.5 4.2 10.5 5.1 12C6.2 13.9 8.7 14.6 10.6 13.4L12.8 15.6L14.2 14.2L12 12C12.9 10.4 12.7 8.4 11.4 7.1C9.8 5.6 7.2 5.6 5.7 7.2ZM9.9 11.4C9.1 12.1 7.9 12.1 7.1 11.4C6.3 10.6 6.3 9.4 7.1 8.6C7.9 7.8 9.2 7.9 9.9 8.6C10.6 9.3 10.7 10.7 9.9 11.4Z" fill="#27A6A4"/>
      </svg>
    `,
  },
  {
    name: 'info-circle',
    icon: `
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7.5 10.375C7.5 10.4438 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4438 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z" fill="#3B9FE7"/>
      </svg>
    `,
  },
  {
    name: 'error-square',
    icon: `
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3333 0H0.666667C0.266667 0 0 0.266667 0 0.666667V11.3333C0 11.7333 0.266667 12 0.666667 12H11.3333C11.7333 12 12 11.7333 12 11.3333V0.666667C12 0.266667 11.7333 0 11.3333 0ZM6.66667 9.33333H5.33333V8H6.66667V9.33333ZM6.66667 7.33333H5.33333V2.66667H6.66667V7.33333Z" fill="#F3BA2F"/>
      </svg>
    `,
  },
  {
    name: 'error-triangle',
    icon: `
      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.59465 1.16656L13.9453 12.1666C14.0038 12.2679 14.0346 12.3829 14.0346 12.4999C14.0346 12.6169 14.0038 12.7319 13.9453 12.8332C13.8868 12.9346 13.8027 13.0187 13.7013 13.0772C13.6 13.1357 13.485 13.1666 13.368 13.1666H0.66665C0.549627 13.1666 0.434666 13.1357 0.333322 13.0772C0.231979 13.0187 0.147822 12.9346 0.0893122 12.8332C0.0308019 12.7319 -7.43221e-07 12.6169 0 12.4999C7.43248e-07 12.3829 0.0308049 12.2679 0.0893165 12.1666L6.43998 1.16656C6.4985 1.06522 6.58265 0.981067 6.684 0.92256C6.78534 0.864053 6.9003 0.833252 7.01732 0.833252C7.13434 0.833252 7.24929 0.864053 7.35064 0.92256C7.45198 0.981067 7.53614 1.06522 7.59465 1.16656ZM6.35065 9.83322V11.1666H7.68398V9.83322H6.35065ZM6.35065 5.16656V8.49989H7.68398V5.16656H6.35065Z" fill="#E65428"/>
      </svg>
    `,
  },
  {
    name: 'list-view',
    icon: `
      <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 0.5H19V2.5H5V0.5Z" />
      <path d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z" />
      <path d="M5 7H19V9H5V7Z" />
      <path d="M3 8C3 8.82843 2.32843 9.5 1.5 9.5C0.671573 9.5 0 8.82843 0 8C0 7.17157 0.671573 6.5 1.5 6.5C2.32843 6.5 3 7.17157 3 8Z" />
      <path d="M5 13.5H19V15.5H5V13.5Z" />
      <path d="M3 14.5C3 15.3284 2.32843 16 1.5 16C0.671573 16 0 15.3284 0 14.5C0 13.6716 0.671573 13 1.5 13C2.32843 13 3 13.6716 3 14.5Z" />
      </svg>
    `,
  },
  {
    name: 'card-view',
    icon: `
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 9.999V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H11V9.999H20ZM9 9.999V18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9.999H9ZM9 0V7.999H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H9ZM19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V7.999H11V0H19Z" />
      </svg>
    `,
  },
];
