<ng-container *ngIf="!showErrorMessage">
  <app-sider
    [menuItemsList]="sideBarMenuItemsList"
    [siderData]="launchBarData"
  ></app-sider>
  <div class="app-layout">
    <app-header></app-header>
    <ng-container *ngIf="!isLoading; else loadingSpinner">
      <div class="app-content" *ngIf="!errorMessage">
        <router-outlet></router-outlet>
      </div>
      <div class="place-items-center-absolute" *ngIf="errorMessage">
        <rd-alert
          type="error"
          [closable]="false"
          [showIcon]="true"
          [message]="errorMessage"
        ></rd-alert>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="showErrorMessage">
  <div>
    {{ authErrorMessage }}
  </div>
</ng-container>

<ng-template #loadingSpinner>
  <div class="place-items-center-absolute">
    <rd-spinner></rd-spinner>
  </div>
</ng-template>

<ng-template #successNotification let-data="data">
  <div class="notification__container">
    <i class="notification__icon" nz-icon nzType="custom:check-circle"></i>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #errorNotification let-data="data">
  <div class="notification__container">
    <i class="notification__icon" nz-icon nzType="custom:error-triangle"></i>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #warningNotification let-data="data">
  <div class="notification__container">
    <i class="notification__icon" nz-icon nzType="custom:error-square"></i>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #infoNotification let-data="data">
  <div class="notification__container">
    <i class="notification__icon" nz-icon nzType="custom:info-circle"></i>
    {{ data?.message }}
  </div>
</ng-template>
