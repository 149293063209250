import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { ApiEndpoints, SOMETHING_WENT_WRONG } from '@core/constants';
import { LaunchBarApiResponse, LaunchBarData, UserDetails } from '@core/models';
import {
  AnalyticsService,
  AuthService,
  CustomIconService,
} from '@core/services';
import {
  RDNotificationService,
  RdSiderMenuItem,
} from '@zs-ca/angular-cd-library';
import { filter, forkJoin } from 'rxjs';
import { AppState } from 'src/app/shared/services/app-state';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  errorMessage = '';

  isLoading = false;

  launchBarData?: LaunchBarData;

  sideBarMenuItemsList: RdSiderMenuItem[] = [];

  @ViewChild('successNotification', { static: false })
  successNotification!: TemplateRef<void>;

  @ViewChild('errorNotification', { static: false })
  errorNotification!: TemplateRef<void>;

  @ViewChild('warningNotification', { static: false })
  warningNotification!: TemplateRef<void>;

  @ViewChild('infoNotification', { static: false })
  infoNotification!: TemplateRef<void>;

  appStateValues: any = {};

  showErrorMessage = true;
  authErrorMessage: any = 'Authenticating, Please wait...';
  appStateSubs: any;
  authServiceSubs: any;
  pageInitialized = false;

  constructor(
    private analytics: AnalyticsService,
    private auth: AuthService,
    private customIconService: CustomIconService,
    private http: HttpClient,
    private notification: RDNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private authService: AuthenticationService,
    private appState: AppState,
    private cookieService: CookieService
  ) {
    this.analytics.initialize();
    this.customIconService.addCustomIconsToNzLibrary();
    this.router.events.pipe(filter((e) => e instanceof RouterEvent)).subscribe({
      next: (e) => {
        this.navigationInterceptor(e as RouterEvent);
      },
    });
  }

  ngAfterViewInit(): void {
    this.notification.addTemplate('success', {
      template: this.successNotification,
      style: { background: '#F3FCF4', border: '1px solid #45C03D' },
    });
    this.notification.addTemplate('error', {
      template: this.errorNotification,
      style: { background: '#FFEDE9', border: '1px solid #FFB6A5' },
    });
    this.notification.addTemplate('warning', {
      template: this.warningNotification,
      style: { background: '#FFFDEF', border: '1px solid #FFDF7E' },
    });
    this.notification.addTemplate('info', {
      template: this.infoNotification,
      style: { background: '#E4F3FB', border: '1px solid #99D3FD' },
    });
  }

  ngOnInit(): void {
    if (environment.authEnabled) {
      this.authenticateUser();
      this.appStateSubs = AppState.appState.subscribe((val: any) => {
        if (val && Object.keys(val).length > 0) {
          this.appStateValues = val;
        } else {
          this.appStateValues = {};
        }
        if (!this.pageInitialized) {
          if (this.cookieService.get(this.authService.accessTokenKey)) {
            this.showErrorMessage = false;
            this.fetchAuthData();
          } else {
            const code = this.getUrlParam('code');
            if (code === 'undefined' || !code) {
              this.authService.login();
            } else if (code) {
              this.router.navigate([], {
                queryParams: {
                  yourParamName: null,
                  youCanRemoveMultiple: null,
                },
                queryParamsHandling: 'merge',
              });
              this.authService.getAccessToken(code);
            }
          }
        }
        this.pageInitialized = true;
      });
    } else {
      this.showErrorMessage = false;
      this.fetchAuthData();
    }
  }

  authenticateUser() {
    this.authServiceSubs = this.authService.authResponse.subscribe((res) => {
      if (res['valid']) {
        this.showErrorMessage = false;
        this.fetchAuthData();
      } else {
        this.showErrorMessage = true;
        this.authErrorMessage = res['message'] || 'Something went wrong.';
      }
    });
  }

  private fetchAuthData(): void {
    this.isLoading = true;
    const launchBar$ = this.http.get<LaunchBarApiResponse>(
      ApiEndpoints.LAUNCH_BAR
    );
    const user$ = this.http.get<UserDetails>(ApiEndpoints.LOGIN);
    forkJoin([launchBar$, user$]).subscribe({
      next: ([launchBarResponse, userDetailsResponse]) => {
        if (launchBarResponse?.applications?.applications?.length) {
          this.launchBarData = { ...launchBarResponse.applications };
          if (userDetailsResponse) {
            this.auth.setUserDetails(userDetailsResponse);
            this.sideBarMenuItemsList = this.auth.getAvailableScreens();
            if (environment.config.client === 'Demo') {
              localStorage.setItem('region', this.auth.selectedRegion());
            } else {
              localStorage.setItem('region', 'US');
            }
          }
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
        this.isLoading = false;
      },
      error: (error) => {
        if (error?.status === 401) {
          this.errorMessage = '';
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
          this.isLoading = false;
        }
      },
    });
  }

  private getTitle() {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        return child.snapshot.data['title'];
      } else {
        return 'Data Explorer';
      }
    }
    return 'Data Explorer';
  }

  /**
   * Show & hide the loading spinner during RouterEvent changes.
   *
   * @param {RouterEvent} event the router event.
   */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    } else if (event instanceof NavigationEnd) {
      this.title.setTitle(this.getTitle());
      this.isLoading = false;
    } else if (
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.isLoading = false;
    }
  }

  getUrlParam(key: string) {
    const results = new RegExp('[?&]' + key + '=([^&#]*)').exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  }

  ngOnDestroy() {
    this.appStateSubs?.unsubscribe();
    this.authServiceSubs?.unsubscribe();
  }
}
