export interface LaunchBarData {
  applications: {
    name: string;
    title: string;
    url: string;
  }[];
  expiry: { contactSupportLink: string; nonzs: string; zs: string };
  myAccount: string;
  signOut: string;
}

export interface LaunchBarApiResponse {
  applications: LaunchBarData;
  status: string;
}

export interface UserDetails {
  auth_status?: string;
  username?: string;
  user_role?: string[];
  session_cookie_details?: any;
  auth_cookie_details?: any;
  permissions?: string[];
  region?: string;
}

export enum AppPages {
  DATASETS = 'datasets',
  ADMIN = 'admin',
}

export const SIDEBAR_MENU_ITEMS = {
  [AppPages.DATASETS]: {
    label: 'Datasets',
    path: '/datasets',
    icon: 'custom:database',
  },
  [AppPages.ADMIN]: {
    label: 'Admin',
    path: '/admin',
    icon: 'custom:team-settings',
  },
};
