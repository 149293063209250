import { HttpClient } from '@angular/common/http';
import { Component, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { API_SUCCESS, ApiEndpoints } from '@core/constants';
import { LaunchBarData } from '@core/models';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-sider',
  templateUrl: './sider.component.html',
  styleUrls: ['./sider.component.scss'],
})
export class SiderComponent {
  @Input() siderData?: LaunchBarData;

  @Input() menuItemsList: RdSiderMenuItem[] = [];

  showHelpModal = false;

  documentFetchInProgress = false;

  authEnabled = environment.authEnabled;

  readonly contactSupportList = [
    {
      label: 'Email',
      value: 'support@zs.com',
      href: 'mailto:support@zs.com',
    },
    {
      label: 'Phone',
      value: 'U.S.: 877-393-9775',
      href: 'tel:8773939775',
    },
  ];

  readonly releaseVersion = 'R12.1';

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngAfterViewInit() {
    if (this.authEnabled) document.body.classList.add('auth-enabled');
  }

  toggleSupportModal($event: boolean): void {
    this.showHelpModal = $event;
  }

  handleBrandClick(): void {
    this.router.navigateByUrl('/');
  }

  fetchFaq() {
    this.documentFetchInProgress = true;
    this.http.get<FaqApiResponse>(ApiEndpoints.FAQ).subscribe({
      next: (response) => {
        this.documentFetchInProgress = false;
        if (response?.status === API_SUCCESS && response?.s3Path?.length) {
          window.open(response.s3Path, '_blank');
        } else {
          // TODO: Handle error.
        }
      },
      error: () => {
        this.documentFetchInProgress = false;
        // TODO: Handle error.
      },
    });
  }

  logoutClick() {
    this.authenticationService.tryLogout();
  }
}

interface FaqApiResponse {
  status: string;
  message: string;
  s3Path: string;
}
