import { Injectable } from '@angular/core';
import { Dataset, DatasetComparison } from '@core/models';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  private _dataset?: Dataset;

  private _datasetComparison: DatasetComparison = {};

  regionChange = new Subject<string>();

  get dataset(): Dataset {
    return this._dataset!;
  }

  set dataset(dataset: Dataset) {
    this._dataset = { ...dataset };
  }

  get datasetComparison(): DatasetComparison {
    return this._datasetComparison;
  }

  getDatasetComparisonObject(datasetId: string): Dataset {
    return this._datasetComparison?.[datasetId];
  }

  addDatasetComparisonObject(dataset: Dataset): void {
    if (Object.keys(this.datasetComparison)?.length >= 2) {
      this._datasetComparison = {};
    }
    this._datasetComparison[dataset.dataSourceId] = { ...dataset };
  }

  updateDatasetComparisonObject(datasetId: string, properties: any): void {
    if (this._datasetComparison[datasetId]) {
      this._datasetComparison[datasetId] = {
        ...this._datasetComparison[datasetId],
        ...properties,
      };
    }
  }
}
