import { Injectable } from '@angular/core';
import { CUSTOM_ICONS } from '@core/constants/icons';
import { NzIconService } from 'ng-zorro-antd/icon';

@Injectable({
  providedIn: 'root',
})
export class CustomIconService {
  constructor(private nzIconService: NzIconService) {}

  /**
   * Add local svg icons to NzIcons library.
   * Usage: <i nz-icon nzType="custom:<name>"></i>
   */
  addCustomIconsToNzLibrary() {
    CUSTOM_ICONS.forEach((customIcon) => {
      this.nzIconService.addIconLiteral(
        `custom:${customIcon.name}`,
        customIcon.icon
      );
    });
  }
}
