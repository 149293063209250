export const kpiList: { label: string; key: KpiProperties }[] = [
  { label: 'Time Frame', key: 'exact_date' },
  { label: 'Patients', key: 'patientCount' },
  { label: 'Population Covered', key: 'populationCovered' },
  { label: 'Claims Covered', key: 'claimCoverage' },
  { label: 'Geography', key: 'geoLocation' },
  { label: 'Geography Depth', key: 'geoDepth' },
];

export type KpiProperties =
  | 'exact_date'
  | 'patientCount'
  | 'populationCovered'
  | 'claimCoverage'
  | 'geoLocation'
  | 'geoDepth';

export const TOOLTIPS = new Map([
  [
    'Age Distribution by Gender',
    'Distribution of patient population by age and gender',
  ],
  [
    'Population Distribution by Ethnicity',
    'Distribution of patient on the basis of ethnicity for the qualified population',
  ],
  [
    'Physician Distribution by State',
    'Disease prevalence across the region, highlighted on the basis of the number of patients reported and physicians in a given area',
  ],
  [
    'Physician Distribution by Specialty',
    'Top Specialties based on physician counts',
  ],
  [
    'Claims Distribution by Payer Type',
    'Distribution of the claims based on the payer types',
  ],
  [
    'Patient Distribution Drugs',
    'Top 10 drugs prescribed to patients of the total drugs available in all the drug classes',
  ],
  [
    'Patient Distribution Conditions',
    'Top 10 conditions diagnosed to patients of the total conditions diagnosed',
  ],
  [
    'Claims & Patients Over Time',
    'Total number of claims/patients in a month with respect to data availability dates',
  ],
  [
    'Overlap of Data',
    'Overlap of the patients common to procedure, diagnosis and prescriptions',
  ],
]);
